<template>
  <a-drawer width="35%" :label-col="6" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="用户昵称" prop="nickName">
        <a-input disabled v-model="form.nickName" placeholder="请输入用户昵称" />
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="电话" prop="moblie">
        <a-input disabled v-model="form.mobile" placeholder="请输入用户电话" />
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="举报类型" prop="labelTypeName">
        <a-input disabled v-model="form.labelTypeName" />
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="举报业务类型" prop="reportType">
        <a-select disabled placeholder="选择举报业务类型" v-model="form.reportType">
          <a-select-option value="1">剧评</a-select-option>
          <a-select-option value="2">动态</a-select-option>
          <a-select-option value="3">评论</a-select-option>
          <a-select-option value="4">店铺</a-select-option>
          <a-select-option value="5">剧本集</a-select-option>
          <a-select-option value="6">用户</a-select-option>
          <a-select-option value="7">店评</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="被举报人" prop="reportedPerson">
        <a-input disabled v-model="form.reportedPerson" />
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="被举报内容" prop="reportTitle">
        <a-textarea v-model="form.reportTitle" :rows="4" disabled />
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="被举报内容创建时间" prop="reportCreateTime">
        <a-date-picker style="width: 100%" v-model="form.reportCreateTime" format="YYYY-MM-DD HH:mm:ss" allow-clear :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="涉及的剧评/动态" prop="reportParentBody" v-if="form.reportType==3">
        <a-textarea v-model="form.reportParentBody" :rows="4" disabled />
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="反馈内容" prop="content">
        <a-textarea v-model="form.content" :rows="2" disabled />
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="图片证据" prop="img">
        <!--        <a-input v-model="form.img" placeholder="请输入图片证据" />-->
        <!--        <img v-for="img in form.img" :src='img' preview style='height:150px;max-width:150px;margin-right:10px;cursor: pointer' alt=""/>-->
        <!--        <template v-for="img in form.img">-->
        <!--          <img v-if="open" :src="img+'?t='+Date.now()" style="height:150px;max-width:150px;margin-right:10px;cursor: pointer" preview alt=""/>-->
        <!--        </template>-->
        <oss-multi-upload v-model="form.img" type="img" :maxCount="0" :maxSize="10" :isRemove="false"></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="举报时间" prop="createTime">
        <a-date-picker style="width: 100%" v-model="form.createTime" format="YYYY-MM-DD HH:mm:ss" allow-clear :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="备注" prop="remark">
        <a-input disabled v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>

      <a-modal v-model="auditVisible" :loading="submitLoading" title="提交审批" @ok="submitAudit">
        <a-select style="width: 250px" v-model="auditStatus" placeholder="请选择审核状态">
          <a-select-option value='1'>举报已处理</a-select-option>
          <a-select-option value='2'>驳回举报，内容不符</a-select-option>
        </a-select>
      </a-modal>

      <div class="bottom-control" v-show="showButton">
        <a-space>
<!--          <a-button type="primary" :loading="submitLoading" @click="submitForm">-->
<!--            保存-->
<!--          </a-button>-->
          <a-button type="primary" @click="auditVisible = true">
            提交审核
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { addReportRecord, getReportRecord, updateReportRecord, auditReport } from '@/api/report/reportRecord'
import Editor from '@/components/Editor'
import OssMultiUpload from '@/views/script/script/modules/OssMultiUpload.vue'
import { auditShopInfoStatus } from '@/api/shop/shopRecord'

export default {
  name: 'CreateForm',
  props: {},
  components: {
    OssMultiUpload,
    Editor
  },
  data() {
    return {
      imgShow: false,
      showButton: true,
      auditVisible: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        userId: null,
        nickName: null,
        mobile: null,
        labelType: null,
        labelTypeName: null,
        reportType: null,
        reportTitle: null,
        reportCreateTime: null,
        reportParentBody: null,
        reportedPerson: null,
        reportId: null,
        content: null,
        img: null,
        modifier: null,
        createTime: null,
        updateTime: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      auditStatus: undefined,
      rules: {
        userId: [
          { required: true, message: '外键 user_user.id不能为空', trigger: 'blur' }
        ],
        labelType: [
          { required: true, message: '反馈标签类型不能为空', trigger: 'change' }
        ],
        reportType: [
          {
            required: true,
            message: '举报内容类型 1:剧本,2:动态,3:评论,4:店铺,5:剧本集,6:用户不能为空',
            trigger: 'change'
          }
        ],
        reportId: [
          { required: true, message: '反馈类型不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '反馈内容不能为空', trigger: 'blur' }
        ],
        img: [
          { required: true, message: '图片证据不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.imgShow = false
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        nickName: null,
        mobile: null,
        labelType: null,
        labelTypeName: null,
        reportType: null,
        reportTitle: null,
        reportCreateTime: null,
        reportParentBody: null,
        reportedPerson: null,
        reportId: null,
        content: null,
        img: null,
        modifier: null,
        createTime: null,
        updateTime: null,
        isDeleted: null,
        remark: null,
        version: null
      }
      this.auditStatus = undefined
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, type) {
      // if (type == 1) {
      //   this.showButton = true
      // }
      // if (type == 2) {
      //   this.showButton = false
      // }
      this.reset()
      this.formType = 2
      this.imgShow = true
      const id = row.id
      getReportRecord(id).then(response => {
        this.form = response.data
        // if(response.data.img) {
        //   this.$nextTick(() => {
        //     // 这里可以进行其他初始化操作
        //     this.form.img = response.data.img.split(",");
        //   });
        // }
        this.open = true
        if (type == 1) {
          this.formTitle = '修改'
        }
        if (type == 2) {
          this.formTitle = '详情'
        }
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {

            if (this.form.img instanceof Array) {
              this.form.img = this.form.img.join(',')
            }

            updateReportRecord(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addReportRecord(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    submitAudit() {
      if (!this.auditStatus) {
        this.$message.warning('请选择审核状态！');
        return
      }
      if (this.form.id !== undefined && this.form.id !== null) {
        this.submitLoading = true
        auditReport({
          id: this.form.id,
          status: this.auditStatus
        }).then(response => {
          this.$message.success(
            '审核成功',
            3
          )
          this.open = false
          this.$emit('ok')
        }).finally(() => {
          this.reset()
          this.auditVisible = false
          this.submitLoading = false
        })
      }
    }
  }
}
</script>
