import request from '@/utils/request'

// 查询举报记录列表
export function listReportRecord(query) {
  return request({
    url: '/report/report/record/list',
    method: 'get',
    params: query
  })
}

// 查询举报记录详细
export function getReportRecord(id) {
  return request({
    url: '/report/report/record/' + id,
    method: 'get'
  })
}

// 新增举报记录
export function addReportRecord(data) {
  return request({
    url: '/report/report/record',
    method: 'post',
    data: data
  })
}

// 修改举报记录
export function updateReportRecord(data) {
  return request({
    url: '/report/report/record',
    method: 'put',
    data: data
  })
}

// 删除举报记录
export function delReportRecord(id) {
  return request({
    url: '/report/report/record/' + id,
    method: 'delete'
  })
}

  // 审核举报内容
export function auditReport(data) {
  return request({
    url: '/report/report/record/audit',
    method: 'post',
    data: data
  })
}
